import React from 'react'
import TopBar from '../Components/common/TopBar'

function TopBarPage() {
  return (
    <>
        <TopBar/>
    </>
  )
}

export default TopBarPage